import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import type { PartnerBranding, OrganisationBranding, Theme } from "@/types";

export const useFrontendThemeStore = defineStore("frontendTheme", () => {
  // State
  const theme = ref<Theme>("light");
  
  const partnerBranding = reactive<PartnerBranding>({
    logo: null,
    link: null,
  });

  const organisationBranding = reactive<OrganisationBranding>({
    name: null,
    logo: null,
  });

  // Actions
  const setPartnerBranding = (payload: PartnerBranding) => {
    partnerBranding.link = payload.link;
    partnerBranding.logo = payload.logo;
  }

  const setOrganisationBranding = (payload: OrganisationBranding) => {
    organisationBranding.logo = payload.logo;
    organisationBranding.name = payload.name;
  }

  return {
    theme,
    partnerBranding,
    setPartnerBranding,
    organisationBranding,
    setOrganisationBranding,
  }
});
